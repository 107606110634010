import ClassicMobile from '@components/layouts/classic-mobile';
import { getLayout } from '@components/layouts/layout';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { RootState } from 'src/root-redux/store';
export { getServerSideProps } from '@framework/ssr/pages';

const Classic = dynamic(() => import('@components/layouts/classic'));

export default function Home() {
  const { i33Settings: { isMobileOnly } } = useSelector((state: RootState) => state);

  return <>
    {isMobileOnly && <ClassicMobile />}
    {!isMobileOnly && <Classic />}
  </>;
}

Home.getLayout = getLayout;
