import dynamic from 'next/dynamic';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { RootState } from 'src/root-redux/store';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useModalAction } from '@components/ui/modal/modal.context';
import { animateScroll } from "react-scroll";

const ShopsNew = dynamic(() => import('@framework/shops/shopsnew'));
const ShopsFeatured = dynamic(() => import('@framework/shops/shopsfeatured'));
const ProductBestseller = dynamic(() => import('@framework/products/product-bestseller'));
const ProductHot = dynamic(() => import('@framework/products/product-hot'));
const ProductAkce = dynamic(() => import('@framework/products/product-akce'));
const ProductFeatured = dynamic(() => import('@framework/products/product-featured'));
const Promotions = dynamic(() => import('@framework/home/promotions'));

const ClassicMobile = () => {
  const { i33Settings: { isMobileOnly }, bannerSettings: { homePage } } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const [activeList, setActiveList] = useState(0);

  const [isShowSearch, setIsShowSearch] = useState(false);
  const elementRef = useRef(null);

  const router = useRouter();
  const { searchText } = router.query;
  const { openModal } = useModalAction();

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      if (element) {
        const elementTop = element.getBoundingClientRect().top;

        setIsShowSearch(elementTop == 0)
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check trạng thái ban đầu

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="sticky top-[106px] z-[100]" ref={elementRef}>
        <ul className="flex items-center overflow-hidden icon-scrollbar-groups">
          {
            [
              t('common:home-title-product-featured'),
              t('text-new-shop-bread-crumb'),
              t('home-shop-featured'),
              t('home-title-productbestseller'),
              t('home-title-producthot'),
              t('home-title-productakce')
            ].map((title: any, index: number) => {
              return <li className={cn("py-2 px-3 min-w-[100px] text-center h-[60px] flex items-center justify-center cursor-pointer", index == activeList ? 'border-b-2 border-accent bg-light text-blue-10 font-semibold' : 'border-b-2 bg-gray-2300 text-gray-1500')}
                onClick={() => {
                  setActiveList(index)
                  animateScroll.scrollToTop();
                }}
                key={index}
              >
                {title}
              </li>
            })
          }
        </ul>
      </div>
      {activeList == 0 && <LazyLoad height={989} debounce={500} throttle={200}>
        <ProductFeatured />
      </LazyLoad>}
      {activeList == 1 && <LazyLoad height={338} debounce={500} throttle={200}>
        <ShopsNew />
      </LazyLoad>}
      {activeList == 2 &&  <LazyLoad height={634} debounce={500} throttle={200}>
        <ShopsFeatured />
      </LazyLoad>}
      {activeList == 3 && <LazyLoad height={700} debounce={500} throttle={200}>
        <ProductBestseller />
      </LazyLoad>}
      {activeList == 4 && <LazyLoad height={781} debounce={500} throttle={200}>
        <ProductHot />
      </LazyLoad>}
      {activeList == 5 && <LazyLoad height={977} debounce={500} throttle={200}>
        <ProductAkce />
      </LazyLoad>}
      {activeList == 6 && <LazyLoad height={442} debounce={500} throttle={200}>
        <Promotions />
      </LazyLoad>}
    </div>
  );
};

export default ClassicMobile;
